<template>
	<div class="flex flex-row flex-wrap justify-start items-start sm:w-auto h-full gap-5" :class="{ 'w-screen px-4 py-6 sm:px-6 sm:py-12': !archive }">
		<a
			v-if="activeTab >= 0"
			href=""
			@click.prevent="
				activeTab = -1;
				$router.push('/osmember/assignMO');
			"
			class="flex sm:hidden justify-center items-center border-2 border-blue-500 text-blue-500 font-semibold p-2 pl-3 rounded-xl"
		>
			<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M6 1L1.70711 5.29289C1.31658 5.68342 1.31658 6.31658 1.70711 6.70711L6 11" stroke="#3377FF" stroke-width="2" stroke-linecap="round" />
			</svg>
			<p class="pl-3">Назад</p>
		</a>

		<!-- Заголовок -->
		<h1 v-if="!archive" class="flex w-full text-xl font-extrabold sm:text-2xl">
			<span class="sm:flex hidden">Назначение медицинских организаций</span>
			<span class="flex sm:hidden">Назначение МО</span>
		</h1>
		<h2 v-if="activeTab !== -1" class="sm:hidden flex w-full text-lg h-6 mb-4 sm:mb-7 font-extrabold sm:text-2xl">
			<span v-if="activeTab === 2022" class="flex">Перечень МО текущего года</span>
			<span v-else-if="activeTab === 0" class="flex">МО, не входящие в перечень МО текущего года</span>
		</h2>

		<!-- Регион -->
		<div v-if="!archive" class="flex flex-col justify-between w-full px-5 py-8 sm:w-7/12 sm:h-40 sm:mr-4 white-round-box">
			<p class="flex h-5 my-2 text-md text-gray-400 font-bold">Регион</p>
			<p class="flex h-5 my-2 text-lg text-gray-900 font-bold">
				{{ getAuthUser.regionName }}
			</p>
		</div>
		<!-- Мониторинг, инструкция -->
		<div v-if="!archive" class="hidden sm:flex flex-col flex-grow justify-between white-round-box w-4/12 sm:h-40 order-3 sm:order-2">
			<a class="flex text-sm text-blue-500 underline" href="https://static-0.minzdrav.gov.ru/system/attachments/attaches/000/041/812/original/Мониторинг__НОК.pdf?1544167048" target="_blank">
				О мониторинге проведения независимой оценки качества условий оказания услуг медицинскими организациями в субъектах Российской Федерации
			</a>
			<a class="flex text-sm text-gray-500 underline" :href="calcHref" target="_blank">
				<span v-show="chairman">Инструкция пользователя "Председателя ОС"</span>
				<span v-show="secretary">Инструкция пользователя "Секретарь ОС"</span>
			</a>
		</div>

		<div class="flex flex-col w-full order-2">
			<!-- Табы -->
			<ul class="hidden sm:flex w-1/2 z-50 pt-2 flex-col sm:flex-row sm:gap-x-5 sm:gap-y-0 sm:-mb-8 bg-transparent">
				<li
					class="flex px-4 py-3.5 font-bold rounded-t-lg cursor-pointer"
					:class="{ 'bg-blue-500  text-white border-none': activeTab === 2022, 'border-t border-r border-l border-gray-400 text-gray-400 bg-white ': activeTab != 2022 }"
					@click.prevent="activeTab = 2022"
					href=""
				>
					Перечень МО текущего года
				</li>
				<li
					class="flex px-4 py-3.5 font-bold rounded-t-lg cursor-pointer"
					:class="{ 'bg-blue-500  text-white border-none': activeTab === 0, 'border-t border-r border-l border-gray-400 text-gray-400 bg-white ': activeTab != 0 }"
					@click.prevent="activeTab = 0"
					href=""
				>
					МО, не входящие в перечень МО текущего года
				</li>
			</ul>

			<ul v-if="activeTab < 0" class="flex sm:hidden w-full flex-col rounded-lg pb-2 overflow-hidden bg-white filter shadow-default" :class="{ hidden: getOpenMO?.name }">
				<li class="flex items-center px-9 py-4 text-gray-400 border-b border-gray-300 font-semibold">Перечень МО</li>
				<li
					class="flex items-center px-10 py-4 text-gray-600 border-b border-gray-300 hover:bg-blue-50 hover:text-blue-500 cursor-pointer"
					:class="{ 'bg-blue-50 text-blue-500 font-bold': activeTab == 2022 }"
					@click.prevent="activeTab = 2022"
					href=""
				>
					Перечень МО текущего года
					<img class="ml-auto" src="@/assets/img/arrow-right.png" />
				</li>
				<li
					class="flex items-center px-10 py-4 text-gray-600 border-b border-gray-300 hover:bg-blue-50 hover:text-blue-500 cursor-pointer"
					:class="{ 'bg-blue-50 text-blue-500 font-bold': activeTab == 0 }"
					@click.prevent="activeTab = 0"
					href=""
				>
					МО, не входящие в перечень МО текущего года
					<img class="ml-auto" src="@/assets/img/arrow-right.png" />
				</li>
			</ul>

			<AssignMOTable v-show="activeTab >= 0" class="flex sm:border-t-2 sm:border-blue-500" :activeTab="activeTab" :archive="archive" :selectedYear="selectedYear" />
		</div>
	</div>
</template>

<script>
// Глобальные данные стора
import { getters } from '@/store/store';
import { gettersLocaly } from '../store/getters';
import { actions } from '../store/actions';
import AssignMOTable from './AssignMOTable.vue';

export default {
	name: 'Main',
	props: {
		archive: {
			type: Boolean,
			default: () => false
		},
		selectedYear: {
			type: Number,
			default: () => 2022
		}
	},
	components: { AssignMOTable },
	computed: {
		...getters,
		...gettersLocaly,
		chairman() {
			return this.getAuthUser.roles.indexOf('OS_Chairman') !== -1;
		},
		secretary() {
			return this.getAuthUser.roles.indexOf('OS_Secretary') !== -1;
		},
		calcHref() {
			return this.chairman
				? 'https://nok.minzdrav.gov.ru/docs/Оценка ОС. Инструкция пользователя председатель ОС.pdf'
				: 'https://nok.minzdrav.gov.ru/docs/Оценка ОС. Инструкция пользователя секретарь ОС.pdf';
		}
	},
	methods: {
		...actions
	},
	data() {
		return {
			activeTab: 2022
		};
	},
	created() {
		if (window.document.documentElement.clientWidth < 636) this.activeTab = -1;
		if (this.$route.query.activeTab) this.activeTab = +this.$route.query.activeTab;
	}
};
</script>

<style scoped>
.white-round-box {
	@apply flex px-10 py-8 rounded-lg bg-white filter drop-shadow-default;
}
</style>
